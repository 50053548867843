import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";
import { CheckboxesContainer } from "./components/CheckboxesContainer";
import { Input } from "./components/Input";
import { Options } from "./components/Options";
import { toast } from "react-toastify";
import Button from "../shared/Button";

import { useHistory } from "react-router-dom";

const LSIAssessment = ({ firebase }) => {
  const authUser = useContext(AuthUserContext);
  const [lsiData, setLsiData] = useState([]);
  const [lsiAnswers, setLsiAnswers] = useState({});
  const [classData, setClassData] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const history = useHistory();

  console.log("LSIAssessment 21 | authUser", classData);

  const lsiLocation = () => {
    const { lsiInitialAssessment, lsiFinalAssessment } =
      classData?.settings || {};

    if (authUser.lsiDone) {
      return "postLSIAnswers";
    }

    if (!lsiInitialAssessment && classData?.settings?.lsiFinalAssessment) {
      return "postLSIAnswers";
    }

    return !authUser.lsiInitialAssessmentComplete
      ? "preLSIAnswers"
      : "postLSIAnswers";
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoadingData(true);
      try {
        await fetchLSIData();
        setLoadingData(false);
      } catch (error) {
        console.error("Error in fetchData", error);
        toast.error("Error getting LSI data", error.message);
        setLoadingData(false);
      }
    };

    fetchData();
  }, [firebase, authUser]);

  useEffect(() => {
    // checks for any lsi data user change and updates the localstorage with the data
    setLSIDataOnLocalStorage();
  }, [lsiAnswers]);

  useEffect(() => {
    const storedValue = JSON.parse(localStorage.getItem(lsiLocation()));
    setLsiAnswers(storedValue);
  }, []);

  const setLSIDataOnLocalStorage = () => {
    const existingLsiAnswers = JSON.parse(
      localStorage.getItem(lsiLocation()) || "{}"
    );

    const updatedLsiAnswers = { ...existingLsiAnswers, ...lsiAnswers };
    localStorage.setItem(lsiLocation(), JSON.stringify(updatedLsiAnswers));
  };

  const fetchLSIData = async () => {
    try {
      const classDataRequest = await firebase
        .classroom(authUser.classUID)
        .once("value");

      if (!classDataRequest.val()) {
        toast.error("Please Join a classroom to see this assessment");
        return;
      }

      const classData = classDataRequest.val();

      setClassData(classData);
      const querySnapshot = await firebase.assessmentQuestions().get();
      const classSpecificQuestionsSnap = await firebase
        .classSpecificAssessmentQuestions(authUser.classUID)
        .get();

      let classSpecificQuestions = classSpecificQuestionsSnap.docs.map(
        (doc) => {
          console.log("CustomAssessment 49 | doc.data", doc.data());
          return {
            ...doc.data(),
            id: doc.id,
          };
        }
      );

      const ignoredSnap = await firebase
        .getIgnoredClassSpecificAssessmentQuestions(authUser.classUID)
        .get();

      const ignoredQuestionsData = ignoredSnap.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      let data = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }))
        .sort((a, b) => a.orderNum - b.orderNum)
        .filter((question) =>
          filterQuestionByAssessmentType(question, classData, authUser)
        );

      data = data.filter(
        (question) =>
          !ignoredQuestionsData.some((ignored) => ignored.id === question.id)
      );

      classSpecificQuestions = classSpecificQuestions
        .sort((a, b) => a.orderNum - b.orderNum)
        .filter((question) =>
          filterQuestionByAssessmentType(question, classData, authUser)
        )
        .filter(
          (question) =>
            !ignoredQuestionsData.some((ignored) => ignored.id === question.id)
        );

      data = [...data, ...classSpecificQuestions];

      setLsiData(data);
    } catch (error) {
      toast.error("Error fetching LSI data", error.message);
      console.error("Error fetching LSI data", error);
    }
  };

  const filterQuestionByAssessmentType = (question, classData, authUser) => {
    if (classData.isDiversion) {
      console.log("LSIAssessment 125 | class is diversion");
      if (lsiLocation() === "preLSIAnswers") {
        return question.preDiversion;
      } else {
        return question.postDiversion;
      }
    } else {
      console.log("LSIAssessment 132 | class is RED");
      if (lsiLocation() === "preLSIAnswers") {
        return question.preLSI;
      } else {
        return question.postLSI;
      }
    }
  };

  const submitLSIAssessment = async () => {
    try {
      console.log("Submitting LSI answers", lsiAnswers);
      const unansweredQuestions = getUnansweredQuestions();

      if (unansweredQuestions.length !== 0) {
        toast.error(
          `Missing required answers on questions ${unansweredQuestions
            .map(({ index }) => `${index + 1}`)
            .join(", ")}`
        );
        return;
      } else {
        if (!authUser?.lsiInitialAssessmentComplete) {
          await firebase
            .preLsiAssessmentResultsNew(authUser.uid)
            .set({ classId: authUser.classUID, lsiAnswers, className: classData.className });
          await firebase.user(authUser.uid).update({
            lsiInitialAssessmentComplete: true,
          });
          toast.success("Initial Assessment Submitted!");
          history.push("/home");
        } else {
          await firebase
            .postLsiAssessmentResults(authUser.uid)
            .set({ classId: authUser.classUID, lsiAnswers, className: classData.className });
          await firebase.user(authUser.uid).update({
            lsiFinalAssessmentComplete: true,
          });
          history.push("/home");
          toast.success("Post LSI Assessment Submitted!");
        }
      }
    } catch (err) {
      console.log("Error in submitLSIAssessment", err.message, lsiAnswers);
    }
  };

  const getUnansweredQuestions = () => {
    const unanswered = [];

    console.log("LSIAssessment 189 | lsi data", lsiData);

    lsiData.forEach((question, questionIndex) => {
      const answer = lsiAnswers[question.id];

      // Check for checkbox questions
      if (question.type === "checkbox") {
        const isCheckboxAnswered =
          answer &&
          Object.values(answer.checked || {}).some((item) => item.checked);
        if (!isCheckboxAnswered && question.required) {
          unanswered.push({ name: question.name, index: questionIndex });
        }
      } else {
        const isAnswered =
          answer && answer?.value && answer?.value.trim() !== "";
        if (!isAnswered && question.required) {
          unanswered.push({ name: question.name, index: questionIndex });
        }
      }
    });

    return unanswered || [];
  };

  const handleChange = (event, index, category, id, type) => {
    const { name, value } = event.target;
    if (value === "/" || value === "Nothing Selected") return;

    setLsiAnswers((prevState) => {
      const newState = {
        ...prevState,
        [id]: {
          name,
          value,
          category: category || "Personal Info",
          type,
        },
      };

      return newState;
    });
  };

  const handleCheckboxClick = (e, index, name, checkboxIndex, category, id) => {
    const { checked, name: label } = e.target;
    setLsiAnswers((prevState) => {
      const existing = prevState[id] || {};
      const checkedItems = existing.checked || {};

      if (checked) {
        checkedItems[checkboxIndex] = { name: label, checked };
      } else {
        delete checkedItems[checkboxIndex];
      }

      return {
        ...prevState,
        [id]: {
          ...existing,
          name,
          category: category || "Personal Info",
          checked: checkedItems,
        },
      };
    });
  };

  const renderQuestion = (question, index) => {
    const { name, category, required, type, id } = question || {};
    const commonProps = {
      key: index,
      index,
      name,
      category,
      required,
      id,
      type,
    };

    switch (question.type) {
      case "text":
        return (
          <Input
            {...commonProps}
            type="text"
            placeholder={question.placeholder}
            onChange={handleChange}
            value={lsiAnswers[question.id]?.value || ""}
          />
        );
      case "checkbox":
        return (
          <CheckboxesContainer
            {...commonProps}
            label={question.name}
            checkboxes={question.checkboxes}
            checked={lsiAnswers[question.id]?.checked || []}
            handleCheckboxClick={handleCheckboxClick}
          />
        );
      case "options":
        return (
          <Options
            {...commonProps}
            options={question.options}
            placeholder={question.placeholder}
            value={lsiAnswers[question.id]?.value}
            onChange={handleChange}
          />
        );
      case "date":
        return (
          <Input
            {...commonProps}
            type="date"
            placeholder={question.placeholder}
            onChange={handleChange}
            value={lsiAnswers[question.id]?.value || ""}
          />
        );
      default:
        return null;
    }
  };

  const adminResetUserAssessments = async () => {
    await firebase.user(authUser.uid).update({
      lsiInitialAssessmentComplete: false,
      lsiFinalAssessmentComplete: false,
    });
  };

  if (loadingData) return <div>Loading...</div>;
  if (!classData) return <div>Loading Class Data...</div>;
  if (
    authUser.lsiInitialAssessmentComplete &&
    authUser.lsiFinalAssessmentComplete
  ) {
    return (
      <div>
        <div>You've already completed this assessment!</div>
      </div>
    );
  }

  return (
    <div className="lsiAssessment__container">
      {authUser.isAdmin ||
        (process.env.NODE_ENV === "development" && (
          <button onClick={adminResetUserAssessments}>
            DEV & ADMIN ONLY | Reset user values
          </button>
        ))}
      <div className="lsititle__container">
        {classData?.isDiversion ? "Diversion" : ""} LSI Assessment
      </div>

      {lsiData.map(renderQuestion)}
      <Button title="Submit Assessment" onClick={submitLSIAssessment} />
    </div>
  );
};

export default withFirebase(LSIAssessment);
