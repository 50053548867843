import React, { useContext, useEffect, useState } from "react";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { AuthUserContext } from "../Session";
import {
  getLocalStorageData,
  setLocalStorageData,
} from "src/utils/localStorageUtils";
import MultiClassroomSelect from "../shared/ClassroomsMultiSelect";

import LSIAssessmentQuestionsMultiSelect from "../shared/LSIAssessmentQuestionsMultiSelect";

import "./index.css";
import moment from "moment";
import { Spin } from "antd";
import { toast } from "react-toastify";

const MultiClassroomView = ({ firebase }) => {
  const authUser = useContext(AuthUserContext);
  const [allClassrooms, setAllClassrooms] = useState(null);
  const [allUsers, setAllUsers] = useState(null);
  const [selectedClassrooms, setSelectedClassrooms] = useState([]);
  const [lsiData, setLsiData] = useState(null);
  const [assessmentQuestions, setAssessmentQuestions] = useState(null);
  const [toastContent, setToastContent] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [loading, setLoading] = useState(true);

  const retrieveClassroomList = async () => {
    let classrooms = getLocalStorageData("classrooms");

    if (classrooms) {
      setAllClassrooms(classrooms);
      // fetchAllClassroomsLSIData();
    }

    const reqClassrooms = await firebase.classrooms().once("value");

    if (
      reqClassrooms.val() &&
      JSON.stringify(reqClassrooms.val()) !== JSON.stringify(classrooms)
    ) {
      setLocalStorageData("classrooms", reqClassrooms.val());
      setAllClassrooms(reqClassrooms.val());
    }
  };

  const fetchAllClassroomsLSIData = async () => {
    try {
      const lsiDataArrays = await Promise.all(
        selectedClassrooms.map(async (className) => {
          console.log('selectedClassrooms', selectedClassrooms)
          return await fetchSingleClassLSIData(className);
        })
      );

      const mergedLsiData = [].concat(...lsiDataArrays);
      setLsiData(mergedLsiData);
      addLSIDataToUsers(allUsers, mergedLsiData);
    } catch (error) {
      console.error("Error fetching classroom LSI data:", error);
      toast.error("Error loading classroom data");
    }
  };

  const fetchSingleClassLSIData = async (className) => {
    // let lsiData = getLocalStorageData(`multiClassroomLsiData_${className}`);

    // if (lsiData) {
    //   console.log("fetched from local storage");
    //   return lsiData;
    // }

    console.log("fetched from database for class Id", className);
    const req = await firebase.preLSIAssessmentResultsByClassId(className).get();
    console.log("req", req);
    const lsiData = req.docs.map((doc) => ({ uid: doc.id, ...doc.data() }));
    console.log("lsiData", lsiData);
    setLocalStorageData(`multiClassroomLsiData_${className}`, lsiData);
    console.log("lsiData", lsiData);
    return lsiData;
  };

  const fetchAllUsersAndFilterByClassId = async () => {
    const usersFromLocalStorage = getLocalStorageData("users");
    if (usersFromLocalStorage) {
      console.log("fetched from local storage");
      const users = Object.values(usersFromLocalStorage).map((user) => ({
        ...user,
        uid: user.uid,
      }));
      return users;
    } else {
      console.log("fetched from database");
      const usersWithClassId = await firebase.users().once("value");
      const users = Object.entries(usersWithClassId.val()).map(
        ([uid, user]) => ({ ...user, uid })
      );
      setLocalStorageData("users", users);
      console.log("users", users);
      return users;
    }
  };

  const handleSubmit = async (selectedClassrooms) => {
    const users = await fetchAllUsersAndFilterByClassId(selectedClassrooms);
    const filteredUsers = Object.values(users).filter(
      (user) =>
        selectedClassrooms.includes(user.classId) &&
        !user.isAdmin &&
        !user.isMentor
    );
    setSelectedClassrooms(selectedClassrooms);
    setAllUsers(filteredUsers);
    fetchAllClassroomsLSIData();
  };

  const addLSIDataToUsers = (users, lsiData) => {
    if (!users || !lsiData) {
      return;
    }

    console.log("users", users.length, "lsiData", lsiData.length);
    const usersWithLSIData = users.map((user) => {
      const userLSIData = lsiData.find((data) => data.uid === user.uid);
      return { ...user, lsi: userLSIData };
    });

    setAllUsers(usersWithLSIData);
  };

  const fetchLSIAssessmentData = async () => {
    try {
      const req = await firebase.assessmentQuestions().get();
      const assessmentQuestions = req.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLocalStorageData("assessmentQuestions", assessmentQuestions);
      setAssessmentQuestions(assessmentQuestions);
    } catch (error) {
      console.error("Error fetching LSI assessment data:", error);
      throw error;
    }
  };

  const refreshLSIAssessmentData = async () => {
    console.log("refreshing assessment questions from database");
    const req = await firebase.assessmentQuestions().get();
    const assessmentQuestions = req.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setLocalStorageData("assessmentQuestions", assessmentQuestions);
    setAssessmentQuestions(assessmentQuestions);
    console.log("refreshed assessment questions", assessmentQuestions);
  };

  const handleQuestionsSubmit = (selectedQuestions) => {
    console.log("Selected Questions:", selectedQuestions);
    setSelectedQuestions(selectedQuestions);
    setLocalStorageData("selectedQuestions", selectedQuestions);
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        await Promise.all([
          retrieveClassroomList(),
          fetchLSIAssessmentData(),
        ]);
        const savedSelectedQuestions = getLocalStorageData("selectedQuestions");
        if (savedSelectedQuestions) {
          setSelectedQuestions(savedSelectedQuestions);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error loading data:', error);
        setLoading(false);
        toast.error('Error loading data');
      }
    };

    loadData();
  }, []);

  // fetch all classrooms lsi data after all the data is loaded
  useEffect(() => {
    if (allClassrooms && selectedClassrooms.length > 0) {
      fetchAllClassroomsLSIData();
    }
  }, [allClassrooms, selectedClassrooms]);

  const sortedUsers = allUsers
    ? [...allUsers].sort((a, b) => {
      const aRiskLevel = selectedQuestions.reduce((acc, selectedQuestion) => {
        // Check if lsiAnswers exists and handle both array and object cases
        const answers = a.lsi?.lsiAnswers ?
          (Array.isArray(a.lsi.lsiAnswers) ? a.lsi.lsiAnswers : Object.values(a.lsi.lsiAnswers)) :
          [];
        const userQuestion = answers.find(
          (answer) => answer.name === selectedQuestion.name
        );
        if (userQuestion) {
          const questionOptions = selectedQuestion.options;
          questionOptions.forEach((option) => {
            if (option.isRisky && option.value === userQuestion.value) {
              acc += 1;
            }
          });
        }
        return acc;
      }, 0);

      const bRiskLevel = selectedQuestions.reduce((acc, selectedQuestion) => {
        const answers = b.lsi?.lsiAnswers ?
          (Array.isArray(b.lsi.lsiAnswers) ? b.lsi.lsiAnswers : Object.values(b.lsi.lsiAnswers)) :
          [];

        const userQuestion = answers.find(
          (answer) => answer.name === selectedQuestion.name
        );
        if (userQuestion) {
          const questionOptions = selectedQuestion.options;
          questionOptions.forEach((option) => {
            if (option.isRisky && option.value === userQuestion.value) {
              acc += 1;
            }
          });
        }
        return acc;
      }, 0);

      return bRiskLevel - aRiskLevel;
    })
    : [];

  const dropoutUsers = sortedUsers.filter((user) => user.inactive);
  const nonDropoutUsers = sortedUsers.filter((user) => !user.inactive);

  const averageRiskLevel = (users) => {
    if (users.length === 0) return 0;
    const totalRiskLevel = users.reduce((acc, user) => {
      let userRiskLevel = 0;
      selectedQuestions.forEach((selectedQuestion) => {
        const answers = user.lsi?.lsiAnswers ?
          (Array.isArray(user.lsi.lsiAnswers) ? user.lsi.lsiAnswers : Object.values(user.lsi.lsiAnswers)) :
          [];
        const userQuestion = answers.find(
          (answer) => answer.name === selectedQuestion.name
        );
        if (userQuestion) {
          const questionOptions = selectedQuestion.options;
          questionOptions.forEach((option) => {
            if (option.isRisky && option.value === userQuestion.value) {
              userRiskLevel += 1;
            }
          });
        }
      });
      return acc + userRiskLevel;
    }, 0);
    return (totalRiskLevel / users.length).toFixed(2);
  };

  const getMostCommonDropoutQuestions = () => {
    const questionCount = {};

    dropoutUsers.forEach((user) => {
      selectedQuestions.forEach((selectedQuestion) => {
        const answers = user.lsi?.lsiAnswers ?
          (Array.isArray(user.lsi.lsiAnswers) ? user.lsi.lsiAnswers : Object.values(user.lsi.lsiAnswers)) :
          [];
        const userQuestion = answers.find(
          (answer) => answer.name === selectedQuestion.name
        );
        if (userQuestion) {
          const questionOptions = selectedQuestion.options;
          questionOptions.forEach((option) => {
            if (option.isRisky && option.value === userQuestion.value) {
              const questionKey = `${selectedQuestion.name} ${option.value}`;
              if (questionCount[questionKey]) {
                questionCount[questionKey]++;
              } else {
                questionCount[questionKey] = 1;
              }
            }
          });
        }
      });
    });

    const sortedQuestions = Object.entries(questionCount).sort(
      (a, b) => b[1] - a[1]
    );

    return sortedQuestions.map(([question, count]) => ({
      question,
      count,
    }));
  };

  const mostCommonDropoutQuestions = getMostCommonDropoutQuestions();

  const getMostCommonNonDropoutQuestions = () => {
    const questionCount = {};

    nonDropoutUsers.forEach((user) => {
      selectedQuestions.forEach((selectedQuestion) => {
        const answers = user.lsi?.lsiAnswers ?
          (Array.isArray(user.lsi.lsiAnswers) ? user.lsi.lsiAnswers : Object.values(user.lsi.lsiAnswers)) :
          [];
        const userQuestion = answers.find(
          (answer) => answer.name === selectedQuestion.name
        );
        if (userQuestion) {
          const questionOptions = selectedQuestion.options;
          questionOptions.forEach((option) => {
            if (option.isRisky && option.value === userQuestion.value) {
              const questionKey = `${selectedQuestion.name}-${option.value}`;
              if (questionCount[questionKey]) {
                questionCount[questionKey]++;
              } else {
                questionCount[questionKey] = 1;
              }
            }
          });
        }
      });
    });

    const sortedQuestions = Object.entries(questionCount).sort(
      (a, b) => b[1] - a[1]
    );

    return sortedQuestions.map(([question, count]) => ({
      question,
      count,
    }));
  };

  const mostCommonNonDropoutQuestions = getMostCommonNonDropoutQuestions();

  if (!authUser.isAdmin) return null;
  if (loading) {
    return (
      <div className="multiclassroom__loading">
        <Spin />
      </div>
    );
  }

  return (
    <div className="multiclassroom__container">
      <MultiClassroomSelect
        classrooms={
          allClassrooms
            ? Object.values(allClassrooms).map(
              (classroom) => classroom.className
            )
            : []
        }
        handleSubmit={handleSubmit}
      />
      <LSIAssessmentQuestionsMultiSelect
        questions={assessmentQuestions}
        handleSubmit={handleQuestionsSubmit}
      />
      {/* <button onClick={refreshLSIAssessmentData}>
        Refresh LSI Assessment Data
      </button> */}
      <div className="data__content">
        {allUsers && <div>Total Users {allUsers.length}</div>}
        <div>
          Program Dropout Rate:{" "}
          {Number(
            (allUsers?.filter((user) => user.inactive).length /
              allUsers?.length) *
            100
          ).toFixed(2)}
          %
        </div>
        <div>Number of Dropouts {dropoutUsers.length}</div>
      </div>
      <div>Avg. Risk Level</div>
      <div className="data__content">
        <div>Dropouts: {averageRiskLevel(dropoutUsers)}</div>
        <div>Non-Dropouts: {averageRiskLevel(nonDropoutUsers)}</div>
      </div>
      <div className="questions__frequency">
        <div>
          <div className="dropout__category">
            Most Common Dropout Questions:
          </div>
          {mostCommonDropoutQuestions.map((item, index) => (
            <div key={index} className="dropout__question">
              {console.log("item", item)}
              <div className="dropout__text">{item.question}:</div>
              <div className="data__number">
                {Number((item.count / dropoutUsers.length) * 100).toFixed(2)}%
              </div>
            </div>
          ))}
        </div>
        <div>
          <div className="dropout__category">
            Most Common Non-Dropout Risk Questions:
          </div>
          {mostCommonNonDropoutQuestions.map((item, index) => (
            <div key={index} className="dropout__question">
              <div className="dropout__text">
                {item.question}:{item.count}
              </div>
              <div className="data__number">
                {Number((item.count / nonDropoutUsers.length) * 100).toFixed(2)}
                %
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="questions__frequency">
        <table>
          <thead>
            <tr>
              <th>Dropout Questions</th>
              <th style={{ width: 130 }}>Dropout %</th>
              <th style={{ width: 130 }}>Non Dropout %</th>
              <th>Non-Dropout Questions</th>
            </tr>
          </thead>
          <tbody>
            {mostCommonDropoutQuestions.map((dropoutItem, index) => (
              <tr key={index}>
                <td>{dropoutItem.question}</td>
                <td>
                  {Number(
                    (dropoutItem.count / dropoutUsers.length) * 100
                  ).toFixed(2)}
                  %
                </td>
                <td>
                  {mostCommonNonDropoutQuestions[index]
                    ? Number(
                      (mostCommonNonDropoutQuestions[index].count /
                        nonDropoutUsers.length) *
                      100
                    ).toFixed(2)
                    : "N/A"}
                  %
                </td>
                <td>
                  {mostCommonNonDropoutQuestions[index]
                    ? mostCommonNonDropoutQuestions[index].question
                    : "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <table>
        <thead>
          <tr className="multiclassroom__headerrow">
            <th>Name</th>
            <th>Classroom</th>
            <th>LSI</th>
            <th>Program Dropout</th>
            <th>LSI Risk Level</th>
            {/* <th>Date of Birth</th> */}
          </tr>
        </thead>
        <tbody>
          {sortedUsers.map((user) => {
            let userRiskLevel = 0;
            let toastQuestions = [];

            selectedQuestions.forEach((selectedQuestion) => {
              const answers = user.lsi?.lsiAnswers ?
                (Array.isArray(user.lsi.lsiAnswers) ? user.lsi.lsiAnswers : Object.values(user.lsi.lsiAnswers)) :
                [];
              const userQuestion = answers.find(
                (answer) => answer.name === selectedQuestion.name
              );

              if (userQuestion) {
                const questionOptions = selectedQuestion.options;

                questionOptions.forEach((option) => {
                  if (option.isRisky && option.value === userQuestion.value) {
                    userRiskLevel += 1;
                    toastQuestions.push({
                      ...option,
                      question: selectedQuestion.name,
                      isRisky: true,
                    });
                  } else if (option.value === userQuestion.value) {
                    toastQuestions.push({
                      ...option,
                      question: selectedQuestion.name,
                      isRisky: false,
                    });
                  }
                });
              }
            });

            // Safely access the date from lsiAnswers
            const answers = user.lsi?.lsiAnswers ?
              (Array.isArray(user.lsi.lsiAnswers) ? user.lsi.lsiAnswers : Object.values(user.lsi.lsiAnswers)) :
              [];
            const dateAnswer = answers[3];
            const date = dateAnswer?.value;

            const validDate = date ? moment(
              date,
              [
                "MMDDYYYY",
                "MM-DD-YYYY",
                "YYYY-MM-DD",
                "MM DD YYYY",
                "MM/DD/YYYY",
              ],
              true
            ).isValid() : false;

            const formattedDate = validDate
              ? moment(date, [
                "MMDDYYYY",
                "MM-DD-YYYY",
                "YYYY-MM-DD",
                "MM DD YYYY",
                "MM/DD/YYYY",
              ]).format("MM/DD/YYYY")
              : "N/A";

            const age = validDate
              ? moment().diff(formattedDate, "years")
              : "N/A";

            return (
              <tr key={user.uid} className="multiclassroom__row">
                <td>{user.displayName}</td>
                <td>{user.classId}</td>
                <td>{user.lsi ? "LSI" : "N/A"} - </td>
                <td style={{ backgroundColor: user.inactive ? "red" : "" }}>
                  {user.inactive ? "Yes" : "No"}
                </td>
                <td
                  style={{ position: "relative" }}
                  className="toast__trigger"
                  onClick={() => {
                    console.log("toastQuestions", toastQuestions);
                    setToastContent(toastQuestions);
                  }}
                >
                  {/* <div>{}</div> */}

                  <ToggleContentBox
                    riskLevel={userRiskLevel}
                    content={toastQuestions}
                    name={user.displayName}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const ToggleContentBox = ({ content, name, riskLevel = 0 }) => {
  const [isVisible, setIsVisible] = useState(false);

  const riskyItems = content.filter((item) => item.isRisky);
  const nonRiskyItems = content.filter((item) => !item.isRisky);

  return (
    <div>
      <div
        className="toggle__box"
        onClick={() => setIsVisible(!isVisible)}
        style={{
          backgroundColor: isVisible ? "green" : "gray",
          cursor: "pointer",
        }}
      >
        <div className="risk__level">{riskLevel}</div>
      </div>
      {isVisible && (
        <div className="toast__container">
          <div className="risk__container">
            <div className="risk__col">
              <div className="toast__name">{name} Risk Questions</div>
              <div className="toast__columns">
                <div className="toast__column">
                  <div className="toast__column__header">Risky</div>
                  {riskyItems.map((item, index) => (
                    <div key={index} className="toast__question">
                      {item.question} - {item.label}
                    </div>
                  ))}
                </div>
              </div>
              <div className="risk__col">
                <div className="toast__column">
                  <div className="toast__column__header">Non-Risky</div>
                  {nonRiskyItems.map((item, index) => (
                    <div key={index} className="toast__question__safe">
                      {item.question} - {item.label}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default compose(withFirebase)(MultiClassroomView);
